@font-face {
  font-family: "doctorSoosBold";
  src: url("./fonts/webfont/Doctor-Soos-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "museo_sans_rounded900";
  src: url("./fonts/webfont/museosansrounded-900-webfont.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "museo_sans_rounded300";
  src: url("./fonts/webfont/museosansrounded-300-webfont.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "visbyRound";
  src: url("./fonts/webfont/Visby-Round.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "visbyRoundBold";
  src: url("./fonts/webfont/VisbyRoundCF-Bold.ttf");
  font-weight: bold;
  font-style: bold;
}

body {
  margin: 0px;
}

.button {
  background-color: #1c2682;
  border-radius: 20px;
  color: #ffffff;
  border: none;
  font-family: "doctorSoosBold";
  font-size: 24px;
  padding: 5px 15px;
  cursor: pointer;
  outline: inherit;
}

.button:hover {
  background-color: #4954b5;
}

/* antd */

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px !important;
  font-weight: 600;
  font-size: 16px !important;
  font-family: "museo_sans_rounded300";
  border-radius: 30px !important;
  width: inherit;
  color: rgb(65 65 65 / 76%);
  border-color: #d9d9d9;
  padding-left: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;
  padding: 4px 20px !important;
}

.ant-input {
  height: 50px !important;
  font-weight: 600;
  font-size: 16px !important;
  font-family: "museo_sans_rounded300";
  border-radius: 30px !important;
  width: 100%;
  text-align: center;
  color: rgb(65 65 65 / 76%) !important;
  border-color: #d9d9d9;
  padding: 4px 20px !important;
}

.ant-picker {
  width: 100%;
  height: 50px;
  border-radius: 30px !important;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px !important;
  font-family: "museo_sans_rounded300";
}

.ant-picker-input > input {
  font-weight: 600;
  font-size: 16px !important;
  font-family: "museo_sans_rounded300";
}

.ant-select-selection--single {
  height: 50px !important;
  font-weight: 600;
  font-size: 16px !important;
  font-family: "museo_sans_rounded300";
  border-radius: 30px !important;
}

.ant-select-selection__rendered {
  display: flex !important;
  align-items: center;
  height: 50px;
}

.ant-form-item-explain {
  margin-bottom: 15px;
}

.ant-form-item {
  margin-bottom: 0px !important;
}
